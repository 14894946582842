export const environment = {
  production: false,
  apiDomain: "https://api-prod.psfyinsights.com", //'https://api-dev.psfyrab.net',
  apiBaseRoute: '/',
  idpBaseUrl:'psfy-insights-prod.auth.us-east-1.amazoncognito.com', //'https://prod.psfyidp.com',
  cognitoUserPoolId: 'us-east-1_mNdKk71GS',
  region: 'us-east-1',
  cognitoAppIdInsightsSpa: '3lm98p94rdt8ebvapkbdnjfcdl',
  cognitoAppIdMcTrade: '74ukqrah0e2oe4dclv4mbsu52r',
  cognitoAppIdMcPro: '6s9ke8i51qu6hggge2k0mgkugv',
  cognitoAppIdPsfyEvents: '2ule7qenn4gumr1b2h0mtgosr9',
  psfyIdpClientId: 'insights_prod_public_client',
  psfyIdpClientSecret: 'f29ec8a3-44d0-ed85-7750-2662cf44e4d7',
  psfyIdpBaseUrl:'https://prod.psfyidp.com'
};